<template>
  <div>
    <base-header class="pb-6" type="primary">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Home</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">Novo</base-button>
          <base-button size="sm" type="neutral">Filtros</base-button>
        </div>
      </div>
      <!-- Card stats -->
        <div class="row">

          <div class="col-xl-3 col-md-6">
            <div class="card bg-white border-0">
              <!-- Card body -->
                <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0 text-black">Imóveis assegurados</h5>
                        <span class="h2 font-weight-bold mb-0 text-black">
                            {{dashboards[0] ? dashboards[0].qtdimoveisassegurados : 0}}
                            /                            
                            {{dashboards[0] ? dashboards[0].qtdimoveis : 0}}
                        </span>
                        <base-progress 
                          class="progress-xs mt-3 mb-0" 
                          type="success" 
                          :value="dashboards[0] ? (dashboards[0].qtdimoveisassegurados/dashboards[0].qtdimoveis)*100 : 0"
                        ></base-progress>
                      </div>
                    </div>
                    <p class="mt-3 mb-0 text-sm">
                      <a href="app/Contrato-management/list-Contrato" class="text-nowrap text-black font-weight-600">Detalhes</a>
                    </p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6" v-if="false">
            <div class="card bg-white border-0">
              <!-- Card body -->
                <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0 text-black">Pró Labore</h5>
                        <span class="h2 font-weight-bold mb-0 text-black">
                          R$ {{dashboards[0] ? dashboards[0].pl : 0}}
                        </span>
                      <p class="mt-1 mb-0 text-sm" v-if="dashboards[0].plma>0">
                          <span class="text-success mr-2" v-if="dashboards[0].pl >= dashboards[0].plma">
                            <i class="fa fa-arrow-up"></i> {{((dashboards[0].pl/dashboards[0].plma)-1)*100}} %
                          </span>
                          <span class="text-danger mr-2" v-if="dashboards[0].pl >= dashboards[0].plma">
                            <i class="fa fa-arrow-down"></i> {{((dashboards[0].pl/dashboards[0].plma)-1)*100}} %
                          </span>
                          <span class="text-nowrap">desde {{meses[(dashboards[0].referencia-(new Date().getFullYear()+"01"))]}}</span>
                      </p>
                      </div>
                    </div>
                    <p class="mt-2 mb-0 text-sm">
                      <a href="app/Fatura-management/list-Fatura" class="text-nowrap text-black font-weight-600">Detalhes</a>
                    </p>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-md-6" >
            <div class="card bg-white border-0">
              <!-- Card body -->
                <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0 text-black">Renovações Pendentes</h5>
                        <span class="h2 font-weight-bold mb-0 text-black">
                            {{dashboards[0] ? dashboards[0].qtdrenovacoesefetivadas : 0}}
                            <!--
                            /                            
                            {{dashboards[0] ? dashboards[0].qtdrenovacoes : 0}}-->
                        </span>
                        <base-progress 
                          class="progress-xs mt-3 mb-0" 
                          type="success" 
                          :value="dashboards[0] ? (dashboards[0].qtdrenovacoesefetivadas/dashboards[0].qtdrenovacoes)*100 : 0"
                        ></base-progress>
                      </div>
                    </div>
                    <p class="mt-3 mb-0 text-sm">
                      <a href="#!" class="text-nowrap text-black font-weight-600">Detalhes</a>
                    </p>
              </div>
            </div>
          </div>
          
          <div class="col-xl-3 col-md-6" >
            <div class="card bg-white border-0">
              <!-- Card body -->
                <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0 text-black">cancelamentos pendentes</h5>
                        <span class="h2 font-weight-bold mb-0 text-black">
                            {{dashboards[0] ? dashboards[0].qtdcancelamentosefetivados : 0}}
                            <!--
                            /                            
                            {{dashboards[0] ? dashboards[0].qtdcancelamentos : 0}}
                            -->
                        </span>
                        <base-progress 
                          class="progress-xs mt-3 mb-0" 
                          type="success" 
                          :value="dashboards[0] ? (dashboards[0].qtdcancelamentosefetivados/dashboards[0].qtdcancelamentos)*100 : 0"
                        ></base-progress>
                      </div>
                    </div>
                    <p class="mt-3 mb-0 text-sm">
                      <a href="#!" class="text-nowrap text-black font-weight-600">Detalhes</a>
                    </p>
              </div>
            </div>
          </div>

        </div>
    </base-header>

    <div class="container-fluid mt--6">
    <!--Tables-->
      <div class="row">
        <div class="col-xl-12">
          <page-new-apolice></page-new-apolice>
        </div>
      </div>
      <!--End tables-->
    </div>
  </div>



</template>
<script>
  // Components
  import BaseProgress from '@/components/BaseProgress';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import Vuex from "vuex";

  //table
  import PageNewApolice from './PageNewApolice';


  export default {
    components: {
      BaseProgress,
      RouteBreadCrumb,
      StatsCard,
      PageNewApolice
    },
    data() {
      return {
        meses:['Dezembro', 'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        dashboards: [{
          qtdimoveisassegurados:0,
          qtdimoveis:0,
          plma:0,
          pl:0,
          referencia: null,
          qtdrenovacoesefetivadas:0,
          qtdrenovacoes:0,
          qtdcancelamentosefetivados: 0,
          qtdcancelamentos:0
        }],
        permissions: [],
        role: [],
      }
    },
    methods: {
      
      async getDashboard() {
          /*
          let params = {
            sort: '-referencia',
            filter: {
              //...(this.navitem_filtros[this.navitem_at] ? this.navitem_filtros[this.navitem_at] : {}),
              //...(this.query ? { codcliente: this.query } : {}),
              //...(this.query ? { nome: this.query } : {}),
            },
            page: {
              number: 1,
              size: 2,
            },
            include: null,
          };

          await this.$store.dispatch("dashboards/list", params).then(() => {
            this.dashboards = this.$store.getters["dashboards/list"];
          });
          */

         //qtdimoveisassegurados
          try {
              let params = {
                filter: {seguroistatu_id: 4},
                page: {number: 1,size: 1,},
                include: "",
              };
              await this.$store.dispatch("contratos/list", params).then(() => {
                this.$set(this.dashboards[0], 'qtdimoveisassegurados', this.$store.getters["contratos/listTotal"]);
              });            
          } catch (error) {
            this.$notify({
              type: "danger",
              message: "Oops, something went wrong!",
            });
          }
          //pro labore
          /*
          try {
              let dateNextMonth15th = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 15).toISOString().slice(0, 10).replace(/-/g, '-');
              let params = {
                filter: {vencimentofatura: dateNextMonth15th},
                page: {number: 1,size: 1,},
                include: "",
              };
              await this.$store.dispatch("faturas/list", params).then(() => {
                this.$set(this.dashboards[0], 'pl', this.$store.getters["faturas/list"][0].valorprolabore);
              });            
          } catch (error) {
            this.$notify({
              type: "danger",
              message: "Oops, something went wrong!",
            });
          }
          */

          //qtdimoveis
          try {
              let params = {
                //filter: {seguroistatu_id: 4},
                page: {number: 1,size: 1,},
                include: "",
              };
              await this.$store.dispatch("contratos/list", params).then(() => {
                this.$set(this.dashboards[0], 'qtdimoveis', this.$store.getters["contratos/listTotal"]);
              });            
          } catch (error) {
            this.$notify({
              type: "danger",
              message: "Oops, something went wrong!",
            });
          }

      }
        
    },
    created() {
      this.getDashboard();
    },
    mounted() {

    },
    
    computed: {
      ...Vuex.mapState({
        me: (state) => state.profile.me,
      }),
    },
    watch: {
      me: {
        handler: function (val) {
          this.roles = val.roles.map((r) => r.name);
          this.permissions = val.roles.flatMap((role) => 
            role.permissions ? role.permissions.map(permission => permission.name) : []
          );
          //console.log('permissions', this.permissions);
          
        },
        deep: true,
      },
    },
  };
</script>
<style></style>
