<template>
  <div class="card">
    <div class="card-header border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Ultimas contratações</h3>
        </div>
        <div class="col text-right">
          <a href="#!" class="btn btn-sm btn-primary">Todos</a>
        </div>
      </div>
    </div>

    <el-table class="table-responsive table"
              :data="seguroincendios"
              header-row-class-name="thead-light">
      <el-table-column label="Locação"
                       min-width="90px"
                       prop="contrato.codigo">
      </el-table-column>
      <el-table-column label="Endereço"
                       min-width="150px"
                       prop="enderecotxt">
                      <template slot-scope="scope">
                        <div>
                          <div><strong> {{ scope.row.enderecotxt }}</strong></div>
                          <div><small><strong>Proponente:</strong></small> {{ scope.row.proponentenome }}</div>
                          <div><small><strong>Beneficiário:</strong></small> {{ scope.row.beneficiarionomes }}</div>
                          <div v-if="scope.row.numeroapolice>0"><small><strong>Apólice: </strong>{{ scope.row.numeroapolice }}</small></div>
                        </div>
                      </template>
      </el-table-column>
      <el-table-column label="Valor"
                       min-width="90px"
                       prop="seguroiparcelamento.valordemaisparcelas">
                        <template slot-scope="scope">
                          <span v-if="scope.row.seguroiparcelamento">
                            {{ scope.row.seguroiparcelamento.qtdparcelas }}x R$ 
                            {{ scope.row.seguroiparcelamento.valordemaisparcelas }}
                          </span>
                        </template>
      </el-table-column>

      <el-table-column label="Arquivo"
                       sortable
                       min-width="90px"
                       prop="numeroapolice">
        <div slot-scope="{ row }" class="table-actions">
          <el-tooltip content="Baixar Proposta" placement="top" v-if="row.numeroproposta != 0 && row.propostapdf == 1">
                  <a
                  type="text"
                  @click="baixarArquivoProposta(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                  >
                  <i class="far fa-file-pdf"></i>
                  </a>
              </el-tooltip>    
              <el-tooltip content="Baixar Apólice" placement="top" v-if="row.numeroapolice != 0 && row.apolicepdf == 1">
                  <a
                  type="text"
                  @click="baixarArquivo(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                  >
                  <i class="fas fa-file-pdf"></i>
                  </a>
              </el-tooltip>   
            </div> 
      </el-table-column>

    </el-table>

  </div>
</template>
<script>
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  export default {
    name: 'page-visits-table',
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {        
      query: null,
      seguroincendios: [],
      navitem_at: 0,
      navitem_filtros: [{},{contratado: 0},{contratado: 1}],
      selectedRows: [],
      sort: "-created_at",

      pagination: {
        perPage: 10,
        currentPage: 1,
      },

      total: 0,
      loading: true,
      };
    },
    
  created() {
    this.getList();
  },
    
  methods: {

     async getList() {
      try {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...({seguroistatu_id: 4 }),
          ...(this.navitem_filtros[this.navitem_at] ? this.navitem_filtros[this.navitem_at] : {}),
          ...(this.query ? { proponentenome: this.query } : {}),
          ...(this.query ? { beneficiarionomes: this.query } : {}),
          ...(this.query ? { enderecotxt: this.query } : {}),
          ...(this.query ? { numeroapolice: this.query } : {}),
          ...(this.query ? { numeroproposta: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        include: "contrato,seguroiparcelamento",
      };
      await this.$store.dispatch("seguroincendios/list", params).then(() => {
        this.seguroincendios = this.$store.getters["seguroincendios/list"];
        this.total = this.$store.getters["seguroincendios/listTotal"];
      });
      this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  }
}
</script>
<style>
</style>
